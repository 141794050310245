/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

/* global I18n */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  boxText: {
    fontSize: "12px",
    padding: "5px 10px",
    resize: "vertical",
    maxHeight: "344px",
  },
  labelContainer: {
    minWidth: 120,
  },
  spanFont: {
    fontSize: "1.2rem !important",
    color: "#595959",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem !important",
    },
  },
  spanContainer: {
    textAlign: "end",
    [theme.breakpoints.down(300)]: {
      textAlign: "start",
    },
  },
}));

const TextField = React.forwardRef(
  (
    {
      textLabel,
      textPlaceholder,
      text,
      styles,
      lengthValue,
      disable,
      callback,
    },
    ref,
  ) => {
    const classes = useStyles();
    const [textQuestion, setTextQuestion] = useState("");
    const [hasBeenFocused, setHasBeenFocused] = useState(false);
    const [length, setLength] = useState(0);

    useEffect(() => {
      setTextQuestion(text);
      if (text === "") {
        setLength(lengthValue);
      }
    }, [text]);

    const handleChange = (e) => {
      const { value } = e.target;
      setTextQuestion(value);
      setLength(lengthValue - value.length);
    };

    const handleBlur = () => {
      callback(textQuestion);
      setHasBeenFocused(true);
    };

    return (
      <Grid className={`form-group ${classes.root}`}>
        <label id="questionField"> {textLabel} </label>
        <textarea
          ref={ref}
          rows="3"
          maxLength={lengthValue}
          placeholder={textPlaceholder}
          value={textQuestion}
          disabled={disable}
          onChange={handleChange}
          onBlur={handleBlur}
          className={`form-control ${classes.boxText} ${styles}`}
          aria-labelledby="questionField"
          aria-describedby="questionText"
          aria-required
        />
        <span id="questionText" role="alert" className={classes.spanFont}>
          {textQuestion.length === 0 && hasBeenFocused
            ? I18n.t("questions.question_required")
            : I18n.t("questions.message_hint", { num: `${length}` })}
        </span>
      </Grid>
    );
  },
);

TextField.propTypes = {
  textLabel: PropTypes.string,
  textPlaceholder: PropTypes.string,
  text: PropTypes.string,
  lengthValue: PropTypes.number,
  styles: PropTypes.string,
  length: PropTypes.string,
  disable: PropTypes.bool,
  callback: PropTypes.func,
};

TextField.displayName = "TextField";
export default TextField;
