/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";
import Stack from "@mui/material/Stack";

import QuestionVoteitem from "../questionVoteItem";
import "./styles.css";

function QuestionItem({ question, questionVoting, callback }) {
  const { author, message, message_edit, vote_count, voted, isAnswered } =
    question;
  const [vote, setVote] = useState(voted);
  const [disable, setDisable] = useState(false);
  const questionOwner = question.authorOwner || false;
  let timeout;

  const cleanMessage = DOMPurify.sanitize(message || message_edit, {
    ALLOWED_TAGS: [],
    ALLOWED_ATTR: [],
  });

  useEffect(() => {
    setVote(voted);
    if (questionOwner) {
      setDisable(true);
    }
  }, [voted, questionOwner]);

  // cleanup timeout if component got unmounted during update
  useEffect(() => {
    return () => clearTimeout(timeout);
  }, []);

  const voteHandler = () => {
    setDisable(true);
    setVote((prev) => !prev);
    callback();
    timeout = setTimeout(() => {
      setDisable(false);
    }, 2000);
  };

  return (
    <Stack
      component="li"
      direction="row"
      justifyContent="space-between"
      className="list-group-item"
    >
      <Stack>
        <p className="question-item-author">
          {author === "Anonymous" || author === undefined
            ? I18n.t("questions.anonymous")
            : author}
        </p>
        <p
          // dangerouslySetInnerHTML might not be necessary anymore SSY-3483
          dangerouslySetInnerHTML={{
            __html: cleanMessage,
          }}
        />
      </Stack>
      {questionVoting && (
        <QuestionVoteitem
          voteCount={vote_count}
          isVoted={vote}
          authorOwner={questionOwner}
          answered={isAnswered}
          disabled={disable}
          callVoted={voteHandler}
        />
      )}
    </Stack>
  );
}

export default QuestionItem;

QuestionItem.propTypes = {
  question: PropTypes.object,
  voted: PropTypes.bool,
  callback: PropTypes.func,
  author: PropTypes.string,
  message: PropTypes.string,
  vote_count: PropTypes.number,
  isAnswered: PropTypes.bool,
  questionVoting: PropTypes.bool,
};
