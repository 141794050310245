/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

/* global I18n */

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import QuestionItem from "../questionItem";
import isEmpty from "lodash/isEmpty";
import ManagerQaList from "../managerQaList";
import useInterval from "@untyped-components/hooks/useInterval";
import Spinner from "@untyped-components/Spinner/Spinner";
import QuestionService from "../services/questionService";
import {
  selectQuestions,
  loadQuestions,
  selectFailedRequests,
} from "@untyped-store/slices/questionsListSlice";
import { styled } from "@mui/material/styles";
import TablePagination, {
  tablePaginationClasses,
  TablePaginationProps,
} from "@mui/material/TablePagination";
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { selectQuestionVoting } from "@untyped-store/selectors/activeEvent";

import { selectSelectedRoom } from "@untyped-store/slices/questionRoomsSlice";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles({
  typography: {
    body2: {
      fontSize: "1.2rem",
    },
  },
  pagination: {
    color: "#717171",
  },
  spinnerContainer: {
    minHeight: 200,
    position: "relative",
  },
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  flexBoxWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  qaList: {
    flex: "auto",
  },
  noVoting: {
    marginTop: "20px",
    flex: "auto",
  },
  paginationRow: {
    alignSelf: "flex-end",
  },
});

const CustomTablePagination = styled((props: TablePaginationProps) => (
  <TablePagination {...props} />
))(() => ({
  [`& .${tablePaginationClasses.displayedRows}`]: {
    fontSize: "1.2rem",
    marginTop: "10px",
  },
}));

const limit = 20;
let showNavigation = false;

function QuestionsList() {
  const classes = useStyles();
  const service = new QuestionService();
  const { eventId, attendeeId } = service.getConfigList();
  const questionVoting = useSelector(selectQuestionVoting);
  const { questions, loading, totalQuestions, error } =
    useSelector(selectQuestions);
  const dispatch = useDispatch();
  const [sortByVote, setSortByVote] = useState(false);
  const [hideAnswered, setHideAnswered] = useState(false);
  const [url, setUrl] = useState(`/${eventId}/questions?offset=0`);
  const [page, setPage] = useState(0);
  const refEl = useRef(null);
  const selectedQuestionRoom = useSelector(selectSelectedRoom);
  const failedRequests = useSelector(selectFailedRequests);
  const refreshTimeSeconds = 20 + failedRequests * 10;

  const loadQuestionParams = {
    url,
    authorId: attendeeId,
    questionVoting,
  };

  if (questions.length !== 0 && !showNavigation) {
    showNavigation = true;
  }

  useEffect(() => {
    const queryStr = [];
    if (sortByVote) queryStr.push("order_by=question_votes_count");
    if (hideAnswered) queryStr.push("exclude_answered=true");
    if (selectedQuestionRoom) {
      queryStr.push(`questions_room=${selectedQuestionRoom.id}`);
    }
    const path = getPath(queryStr, `/${eventId}/questions?`);
    setUrl(`${path}offset=${page * limit}`);
  }, [sortByVote, hideAnswered, page, selectedQuestionRoom]);

  useEffect(() => {
    (async () => {
      fetchQuestions();
    })();
  }, [url]);

  useInterval(() => {
    fetchQuestions();
  }, refreshTimeSeconds * 1000);

  const fetchQuestions = () => {
    if (!selectedQuestionRoom) return;
    dispatch(loadQuestions(loadQuestionParams));
  };
  const getPath = (arr, startStr) => {
    return !isEmpty(arr)
      ? arr.reduce((s, c) => `${s}${c}&`, startStr)
      : startStr;
  };

  const setScroll = (target) => {
    if (target.current !== null) {
      target.current.scrollTop = 0;
    }
  };

  const handlerQuestion = (item) => {
    service
      .setDataVote(item)
      .then(() => {
        setTimeout(() => {
          fetchQuestions();
        }, 500);
      })
      .catch(
        (err) =>
          new Error(`Couldn't update question via POST request, ${err} `),
      );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setScroll(refEl);
  };

  const sortByVotes = (value) => {
    setSortByVote(value);
    setPage(0);
    setScroll(refEl);
  };

  const toggleHideAnswered = () => {
    setHideAnswered((prev) => !prev);
    if (page !== 0) {
      setPage(0);
    }
    if (!showNavigation) {
      showNavigation = true;
    }
    setScroll(refEl);
  };
  const showVoting =
    questionVoting && showNavigation ? classes.qaList : classes.noVoting;

  const showListComponent = () => {
    if (!loading && error === "") {
      return (
        <>
          {questionVoting && showNavigation && (
            <ManagerQaList
              sortByVotes={sortByVotes}
              toggleHideAnswered={toggleHideAnswered}
              hideAnswered={hideAnswered}
            />
          )}
          {isEmpty(questions) && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Typography variant="errorHint">
                {I18n.t("questions.empty")}
              </Typography>
            </Box>
          )}
          {!isEmpty(questions) && (
            <>
              <div
                className={`m-2 questions-list ${showVoting}`}
                ref={refEl}
                style={{
                  position: "relative",
                  overflowY: "scroll",
                  wordBreak: "break-word",
                  borderTop: "1px solid #f3f5f9",
                }}
                tabIndex={0}
              >
                <ul
                  className="list-group no-radius m-b-none m-t-n-xxs list-group-alt list-group-lg"
                  style={{ width: "100%" }}
                >
                  {questions.map((item) => (
                    <QuestionItem
                      key={item.encoded_id}
                      question={item}
                      questionVoting={questionVoting}
                      callback={() => handlerQuestion(item)}
                    />
                  ))}
                </ul>
              </div>

              <div className={classes.paginationRow}>
                <ThemeProvider theme={(outerTheme) => createTheme(outerTheme)}>
                  <CustomTablePagination
                    component="div"
                    count={totalQuestions}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[limit]}
                    className={classes.pagination}
                    getItemAriaLabel={(type) => {
                      if (type === "first") {
                        return I18n.t("questions.first_page");
                      }
                      if (type === "last") {
                        return I18n.t("questions.last_page");
                      }
                      if (type === "next") {
                        return I18n.t("questions.next_page");
                      }
                      if (type === "previous") {
                        return I18n.t("questions.previous_page");
                      }
                    }}
                    labelDisplayedRows={({ count, to, from }) => {
                      return `${from} – ${to} ${I18n.t("questions.of")} ${count !== -1 ? count : `${I18n.t("questions.more_than")} ${to}`}`;
                    }}
                  />
                </ThemeProvider>
              </div>
            </>
          )}
        </>
      );
    }
  };

  return (
    <>
      {loading && <Spinner className="title-empty-box" />}
      {error && (
        <div className="title-empty-box">
          {I18n.t("questions.request_failed_reloading_in", {
            seconds: refreshTimeSeconds,
          })}
        </div>
      )}
      <div className={classes.flexBoxWrapper}>{showListComponent()}</div>
    </>
  );
}

export default QuestionsList;
