/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

/* global I18n */

import React from "react";

import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import BlockIcon from "@material-ui/icons/Block";
import IconButton from "@material-ui/core/IconButton";

import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

const CustomTooltipp = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: "12px",
  },
}));

const BadgeAnswered = styled("span")(() => ({
  backgroundColor: "#3C631D",
  alignSelf: "baseline",
}));

const BadgeVotes = styled("span")(() => ({
  backgroundColor: "#445788",
  alignSelf: "baseline",
}));

type TProps = {
  voteCount: number;
  isVoted: boolean;
  authorOwner: boolean;
  answered: boolean;
  disabled: boolean;
  callVoted: (voted: boolean) => void;
};

function QuestionVoteitem({
  voteCount,
  isVoted,
  authorOwner,
  answered,
  disabled,
  callVoted,
}: TProps) {
  const handleClick = () => {
    if (!disabled) {
      callVoted(!isVoted);
    }
  };

  const buttonTitle = authorOwner
    ? I18n.t("questions.disabled_vote")
    : isVoted
      ? I18n.t("questions.can_unvote")
      : I18n.t("questions.can_vote");

  const showVotes = () => {
    return (
      !answered && (
        <Stack direction="row" gap={0.5}>
          <CustomTooltipp
            arrow
            enterDelay={2000}
            placement="top"
            title={buttonTitle}
            describeChild={false}
          >
            <span>
              <IconButton
                onClick={handleClick}
                disabled={disabled || authorOwner}
                size="small"
                aria-label={buttonTitle}
              >
                {!authorOwner ? (
                  isVoted ? (
                    <ThumbUpIcon />
                  ) : (
                    <ThumbUpOutlinedIcon />
                  )
                ) : (
                  <BlockIcon />
                )}
              </IconButton>
            </span>
          </CustomTooltipp>
          <BadgeVotes aria-label={I18n.t("questions.likes")} className="badge">
            {voteCount}
          </BadgeVotes>
        </Stack>
      )
    );
  };

  const showAnswered = () => {
    return (
      answered && (
        <BadgeAnswered className="label">
          {I18n.t("questions.answered")}
        </BadgeAnswered>
      )
    );
  };
  return (
    <>
      {showVotes()}
      {showAnswered()}
    </>
  );
}

export default QuestionVoteitem;
