/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React, { useState, forwardRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltUp } from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";

import { selectPublicQuestionsLiveOnly } from "@untyped-store/selectors/activeEvent";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `0 0 ${theme.spacing(1)}px 0`,
    color: "#717171",
  },
  groupIcons: {
    paddingRight: 2,
    color: "#424242",
  },
  arrowIcons: {
    fontSize: 12,
    color: "#424242",
  },
  visibilityIcon: {
    color: "#424242",
  },
  toggleButtons: {
    padding: 4,
    textAlign: "start",
    marginBlock: "10px",
  },
  endButton: {
    padding: 4,
    textAlign: "end",
    marginBlock: "10px",
  },
  btn: {
    textTransform: "none",
    fontSize: "1.2rem",
    maxHeight: 31,
    "&:focus": {
      outline: "none",
      border: "1px solid rgba(0, 0, 0, 0.15)",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem",
    },
  },
  activeBtn: {
    color: "#424242",
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    border: "1px solid rgba(0, 0, 0, 0.15)",
    padding: "7px 12px",
    "&:hover": {
      color: "#424242",
      backgroundColor: "rgba(0, 0, 0, 0.15)",
      borderColor: "rgba(0, 0, 0, 0.15)",
    },
  },
  inactiveBtn: {
    color: "#424242",
    backgroundColor: "transparent",
    border: "1px solid rgba(0, 0, 0, 0.15)",
    padding: "7px 12px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      borderColor: "#e2e6ea",
    },
  },
}));

const TooltipToggleButton = forwardRef((props, ref) => {
  const { TooltipProps, ...rest } = props;

  return (
    <Tippy {...TooltipProps}>
      <ToggleButton ref={ref} {...rest} />
    </Tippy>
  );
});

TooltipToggleButton.displayName = "TooltipToggleButton";

function ManagerQaList({ sortByVotes, hideAnswered, toggleHideAnswered }) {
  const classes = useStyles();
  const [alignment, setAlignment] = useState("time");
  const publicQuestionsLiveOnly = useSelector(selectPublicQuestionsLiveOnly);

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      const value = newAlignment === "vote";
      sortByVotes(value);
    }
  };

  const handleHideClick = () => {
    toggleHideAnswered();
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={4} className={classes.toggleButtons}>
        <ToggleButtonGroup
          value={alignment}
          exclusive
          onChange={handleAlignment}
          aria-label={I18n.t("questions.question_sorting")}
          size="small"
        >
          <TooltipToggleButton
            value="time"
            className="questions-time-sort"
            aria-label={I18n.t("questions.sort_by_time")}
            TooltipProps={{
              content: I18n.t("questions.sort_by_time"),
            }}
          >
            <AccessTimeIcon className={classes.groupIcons} />
            <FontAwesomeIcon
              icon={faLongArrowAltUp}
              className={classes.arrowIcons}
            />
          </TooltipToggleButton>
          <TooltipToggleButton
            value="vote"
            className="questions-vote-sort"
            aria-label={I18n.t("questions.sort_by_votes")}
            TooltipProps={{
              content: I18n.t("questions.sort_by_votes"),
            }}
          >
            <ThumbUpIcon className={classes.groupIcons} />
            <FontAwesomeIcon
              icon={faLongArrowAltUp}
              className={classes.arrowIcons}
            />
          </TooltipToggleButton>
        </ToggleButtonGroup>
      </Grid>
      {!publicQuestionsLiveOnly && (
        <Grid item xs={8} className={classes.endButton}>
          <Tippy content={I18n.t("questions.toggle_answered")} delay={2000}>
            <Button
              variant="outlined"
              className={`${`${classes.btn} answered-questions`} ${hideAnswered ? classes.activeBtn : classes.inactiveBtn}`}
              startIcon={
                hideAnswered ? (
                  <VisibilityIcon className={classes.visibilityIcon} />
                ) : (
                  <VisibilityOffIcon className={classes.visibilityIcon} />
                )
              }
              onClick={handleHideClick}
            >
              {hideAnswered
                ? I18n.t("questions.show_answered")
                : I18n.t("questions.hide_answered")}
            </Button>
          </Tippy>
        </Grid>
      )}
    </Grid>
  );
}

ManagerQaList.propTypes = {
  sortByVotes: PropTypes.func,
  hideAnswered: PropTypes.bool,
  toggleHideAnswered: PropTypes.func,
};

TooltipToggleButton.propTypes = {
  TooltipProps: PropTypes.object,
};

export default ManagerQaList;
